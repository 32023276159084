import React from "react";
import Img from "gatsby-image";

// Sections
import BlogHeader from "./partials/BlogHeader";
import BlogPost from "./partials/BlogPost";

// Images
import Image from "../elements/Image";

const PostSection = ({ data, config }) => {
	return (
		<section className="section-blog section-post">
			<div className="container">
				<div className="section-blog__wrapper">
					<BlogHeader />
					<div className="section-blog__wrapper__content">
						<article>
							<div className="section-blog__wrapper__content--image">
								<div className="section-blog__wrapper__content--meta">
									{data.frontmatter.date} • {data.timeToRead} min read
								</div>
								<Image src={data.frontmatter.image} alt={data.frontmatter.title} className="img-fluid" />
							</div>
							<div className="section-blog__wrapper__content__inner">
								<div className="section-blog__wrapper__content__author">
									<div className="section-blog__wrapper__content__author--image">
										<Img fluid={data.frontmatter?.author?.frontmatter?.photo?.childImageSharp?.fluid} alt={data.frontmatter?.author?.frontmatter?.name} className="img-fluid" />
									</div>
									<div className="section-blog__wrapper__content__author--name">
										<h5>{data.frontmatter.author.frontmatter.name}</h5>
									</div>
								</div>
								<h1>{data.frontmatter.title}</h1>
								<div className="section-blog__wrapper__content__inner--text" dangerouslySetInnerHTML={{ __html: data.html }}></div>
								<div className="section-blog__wrapper__content__inner--share">
									<label>Share post:</label>
									<div className="section-blog__wrapper__content__inner--share--social-icons">
										<a href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(data.frontmatter.title)}&url=${encodeURIComponent(config.domain + data.fields.slug)}&via=orangejellyhq`} target="_blank">
											<svg width="34" height="32" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
												<g clipPath="url(#clip0)">
													<path
														d="M8.5 15.8932C13.1944 15.8932 17 12.3414 17 7.96014C17 3.57884 13.1944 0.0270996 8.5 0.0270996C3.80558 0.0270996 0 3.57884 0 7.96014C0 12.3414 3.80558 15.8932 8.5 15.8932Z"
														fill="#FFFFFF"
													/>
													<path
														d="M13.2551 5.20836C12.9098 5.35711 12.5246 5.45627 12.1395 5.49346C12.5379 5.27034 12.8566 4.91088 13.0027 4.48943C12.6309 4.70015 12.2059 4.8489 11.7676 4.93567C11.409 4.5762 10.9043 4.36548 10.3465 4.36548C9.2707 4.36548 8.39414 5.18357 8.39414 6.1876C8.39414 6.33634 8.40742 6.47269 8.44727 6.60904C6.82695 6.53467 5.3793 5.80334 4.42305 4.70015C4.25039 4.97285 4.15742 5.28274 4.15742 5.61741C4.15742 6.24958 4.50273 6.80737 5.0207 7.12965C4.70195 7.11725 4.39648 7.04288 4.13086 6.90653C4.13086 6.91893 4.13086 6.91893 4.13086 6.93132C4.13086 7.81139 4.8082 8.55512 5.69805 8.71626C5.53867 8.75344 5.36602 8.77823 5.18008 8.77823C5.06055 8.77823 4.92773 8.76584 4.8082 8.74105C5.06055 9.45998 5.77773 9.99298 6.62773 10.0054C5.96367 10.4888 5.11367 10.7863 4.19727 10.7863C4.03789 10.7863 3.87852 10.7739 3.73242 10.7615C4.5957 11.2821 5.61836 11.5796 6.7207 11.5796C10.3066 11.5796 12.2723 8.80303 12.2723 6.39832C12.2723 6.32395 12.2723 6.23718 12.2723 6.16281C12.6707 5.89011 13.0027 5.56783 13.2551 5.20836Z"
														fill="black"
													/>
												</g>
												<defs>
													<clipPath id="clip0">
														<rect width="17" height="15.8661" fill="white" transform="translate(0 0.0270996)" />
													</clipPath>
												</defs>
											</svg>
										</a>
									</div>
								</div>
							</div>
						</article>
					</div>
				</div>
				<div className="section-blog__related-posts">
					<h3>Related posts</h3>
					<div className="row">
						{data.relatedPosts.slice(0, 3).map((post, key) => {
							return (
								<div className="col-lg-4" key={key}>
									<BlogPost image={post.frontmatter.image} date={post.frontmatter.date} readingTime={post.timeToRead} title={post.frontmatter.title} link={post.fields.slug} layout="small" />
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</section>
	);
};

export default PostSection;
