import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

// Sections
import PostSection from "../../components/sections/PostSection";
import Cta from "../../components/sections/Cta";
import Layout from "../../layouts"

const Post = ({ data }) => {
	return (
		<Layout>
      <Helmet>
			  <title>Orange Jellyfish | Blog | {data.post.frontmatter.title}</title>
      </Helmet>
			<PostSection data={data.post} config={data.site.config} />
			<Cta />
		</Layout>
	);
};

export default Post;

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      ...ConfigSiteTitle
      ...ConfigSiteDomain
      ...ConfigBlogBasePath
    }
    post: markdownRemark(
      fields: {
        slug: {
          eq: $slug
        }
      }
    ) {
      html
      timeToRead
      frontmatter {
        title
        image
        date(formatString: "MMMM DD, YYYY")
        tags
        author {
          frontmatter {
            photo {
              childImageSharp {
                fluid(maxWidth: 125) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            name
          }
        }
      }
      fields {
        slug
      }
      relatedPosts {
        frontmatter {
          title
          image
          date(formatString: "MMMM DD, YYYY")
        }
        fields {
          slug
        }
        timeToRead
      }
    }
  }
`;
